.styles_wrapper__1kG3C {
    background: none !important;
    padding: 0 !important;
    min-height: auto !important;
  }
  
  .styles_gridItem__nPXhF {
    padding: 0 !important;
    margin-right: .5rem !important;
    margin-bottom: .5rem !important;
  }
  
  .styles_gridItem__nPXhF figure > div > img:not(:hover) {
    opacity: 1 !important;
  }
  
  .styles_gridItem__nPXhF figure > div > img:hover {
    opacity: .75 !important;
  }
  
  .styles_grid__1mw-l {
    margin: 0 !important;
    max-width: none !important;
    justify-content: start !important;
  }
  
  .styles_gridItem__nPXhF figcaption {
    background: none !important;
    color: #888 !important;
    padding: .5rem !important;
    font-size: .75rem !important;
  }
  
  .styles_gridItem__nPXhF figcaption:hover {
    text-decoration: none !important;
  }

  .styles_gridItem__nPXhF {
    min-width: 200px !important;
    max-width: 200px !important;
    flex: 1 1 200px !important;
}