.file-card {
    float: left;
    width: 250px;
}

.file-card a {
    text-decoration: none;
}

.file-header {
    text-align: center;
}

.file-header img {
    width: 120px;
}

.file-header img:hover {
    opacity: .75 !important;
}

.file-footer {
    padding: .5rem !important;
    text-align: center;
    color: #888;
}

.file-footer a {
    color: #888;
    font-size: .75rem;
    text-transform: uppercase;
}

.file-footer a:hover {
    text-decoration: underline;
}

.seperator::after {
    content: '|';
    margin-left: 5px;
    margin-right: 5px;
}