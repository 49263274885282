* {
  box-sizing: border-box;
}

body {
  overflow-y: scroll;
  font-size: 22px !important;
  color: #FCFBEE !important;
  padding: 0;
  margin: 0;
  background: rgb(28, 28, 66);
  background: linear-gradient(180deg, rgba(28, 28, 66, 1) 0%, rgba(20, 20, 45, 1) 100%);
  background-repeat: no-repeat;
  background-attachment: fixed;
  font-family: 'Roboto', sans-serif;
  letter-spacing: .025rem;
}

.container {
  padding: 3rem 6rem;
  max-width: 1800px !important;
  min-height: 100vh;
}

h1 {
  margin-bottom: .5rem !important;
  font-size: 5rem !important;
  font-family: 'Poiret One', cursive;
  color: #ff00b7 !important;
  font-weight: 600 !important;
}

h1 a {
  color: #ff00b7 !important;
  text-decoration: none !important;
}

h1 span {
  color: #FCFBEE;
  margin-left: 0.5rem;
}

h2 {
  margin-bottom: 0 !important;
  position: relative;
  bottom: 8px;
}

h3 {
  font-size: 1.25rem !important;
  font-weight: 400 !important;
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.text-white {
  color: #FCFBEE !important;
}

.card {
  background: transparent !important;
  border: 0 !important;
}

.card-header {
  padding: .5rem 1rem !important;
  font-size: 2.5rem !important;
  border: none;
  border-radius: 0;
  font-weight: normal !important;
  color: #c58b50 !important;
  background: transparent !important;
  font-family: 'Dosis', sans-serif !important;
  letter-spacing: .05rem;
  font-weight: bold;
  font-size: 1.6rem;
  font-family: sans-serif;
}

.card-body {
  border: 0 !important;
}

.card-image {
  border-radius: 500px !important;
  max-width: 500px;
  border: solid 2px #ff00b7;
  float: right;
}

.box {
  border-radius: 10px;
  padding: 1.5rem 3rem;
  background: #3b3262;
  text-align: center;
}

.box>.card {
  margin-bottom: 0 !important;
}

.box .card-header {
  font-size: 2rem !important;
}

.box-2 {
  background: #3b3262;
  padding: 2rem;
  border-radius: 10px;
  margin-bottom: 1rem;
}

.group-title {
  font-weight: bold;
  font-size: 1.5rem;
  color: rgb(221, 188, 105);
  margin-top: 2rem;
  margin-bottom: 0;
}

.group-description {
  color: #bbb;
}

.strong {
  font-weight: bold;
  font-size: 1.25rem;
  text-transform: uppercase;
  color: #c58b50;
}

a {
  color: white !important;
}

.nav-pills {
  border-top: solid 1px #9089ae;
  margin-top: 1.5rem;
}

.nav-pills .nav-link {
  color: #FFF !important;
  letter-spacing: .05rem !important;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  border-radius: 0 0 0.5rem 0.5rem;
}

.nav-link.active {
  background-color: #3b3262 !important;
}

.nav-link:not(.active):hover {
  color: #d9aaf6 !important;
}

.footer {
  text-align: center;
  padding: 2rem 2rem;
  color: #999 !important;
  font-size: 1rem;
}

.header .social {
  margin-top: 2.25rem;
  float: right;

  img {
    width: 2.5rem;
  }
}

.footer .social {
  margin-top: 1rem;

  img {
    width: 2rem;
  }
}

.landing {
  position: relative;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
  max-height: 800px;
  overflow: hidden;
}

.landing>img {
  width: 100%;
}

.landing .landing-overlay {
  position: absolute;
  width: 50%;
  background: #580358;
  opacity: .7;
  top: 0;
  bottom: 0;
  left: 0;
}

.landing .landing-overlay p {
  font-size: 7rem;
  line-height: 7rem;
  font-family: 'Dosis', sans-serif !important;
  position: absolute;
  bottom: 50px;
  left: 50px;
  right: 50px;
}

.landing .landing-overlay span {
  color: goldenrod;
}

.section-lead {
  font-size: 5rem;
  line-height: 6rem;
  margin-bottom: 2rem;
  color: #c58b50 !important;
  font-family: 'Dosis', sans-serif !important;
  letter-spacing: .33rem;
}

p {
  line-height: 2.5rem;
}

ul {
  margin: 0;
  list-style: none;
  padding: 0;
  line-height: 2rem;
}

.bullets ul ul {
  margin-left: 2.25rem;
}

.bullets ul li::before {
  content: "›";
  margin-right: 1rem;
  font-weight: bold;
  color: #198754;
  font-size: 2rem;
}

.bullets ul li li::before {
  content: "✓";
}

.bullets>li {
  margin-bottom: 1rem;
}

.form-group {
  margin-bottom: 1rem;
  position: relative;
}

.form-control {
  padding: 2rem 1.25rem .75rem;
  font-size: 1.1rem;
  background: #e7e3e8;
}

.form-group label {
  font-size: .8rem;
  opacity: .8;
  position: absolute;
  left: 1rem;
  top: 0.75rem;
  color: gray;
}

.form-control::placeholder {
  position: absolute;
  top: 1.65rem;
  cursor: pointer;
}

.btn-lg {
  padding: 1.25rem;
}

.rounded-pill {
  padding: .5rem 1.25rem;
  border-radius: 10px !important;
  text-transform: uppercase;
  background-color: #5f62bd !important;
  font-weight: bold;
}

#pricing-image {
  max-width: 500px;
  margin-left: 2rem;
  margin-bottom: 2rem;
}

.btn-primary {
  background: #198754 !important;
  border: none !important;
}

.btn-primary:hover {
  background: #1a794c !important;
}


/* RESPONSIVE STYLES */

@media (max-width: 432px) {

  .landing .landing-overlay p {
    font-size: 1.5rem;
    line-height: 2rem;
    bottom: 15px;
    left: 15px;
    right: 15px;
  }
}

@media (min-width: 433px) and (max-width: 576px) {

  .landing .landing-overlay p {
    font-size: 1.5rem;
    line-height: 2.5rem;
    bottom: 20px;
    left: 20px;
    right: 20px;
  }
}

@media (min-width: 577px) and (max-width: 768px) {

  .landing .landing-overlay p {
    font-size: 2rem;
    line-height: 3rem;
    bottom: 20px;
    left: 20px;
    right: 20px;
  }
}


@media (max-width: 768px) {
  body {
    font-size: 18px !important;
  }


  img {
    max-width: 100% !important;
  }

  p {
    line-height: 2rem;
  }

  .container {
    padding: 1.25rem;
  }

  h1 {
    font-size: 3rem !important;
    margin-bottom: .75rem !important;
  }

  .lead {
    font-size: 1rem;
  }

  .card-image {
    float: none !important;
  }

  .section-lead {
    font-size: 2.75rem !important;
    line-height: 3.25rem;
  }

  .nav-link {
    padding: 0.25rem 0.5rem;
    font-size: 1rem;
  }

  .bullets ul li {
    font-size: 1rem;
  }

  .bullets ul li::before {
    font-size: 1.5rem;
    margin-right: .5rem;
  }
}

@media (max-width: 992px) {
  .header .social {
    display: none;
  }
}


@media (min-width: 993px) {
  .footer .social {
    display: none;
  }
}

@media (min-width: 769px) and (max-width: 992px) {

  .landing .landing-overlay p {
    font-size: 3rem;
    line-height: 4rem;
    bottom: 20px;
    left: 20px;
    right: 20px;
  }
}

@media (min-width: 993px) and (max-width: 1200px) {

  .landing .landing-overlay p {
    font-size: 4rem;
    line-height: 5rem;
    bottom: 30px;
    left: 30px;
    right: 30px;
  }
}


@media (min-width: 1201px) and (max-width: 1400px) {

  .landing .landing-overlay p {
    font-size: 4.5rem;
    line-height: 6rem;
  }

}

@media (min-width: 1401px) and (max-width: 1840px) {

  .landing .landing-overlay p {
    font-size: 6rem;
    line-height: 8rem;
  }

}

@media (min-width: 769px) and (max-width: 1840px) {
  .container {
    padding: 3rem;
  }
}